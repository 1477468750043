import classic from 'ember-classic-decorator';
import Base from 'ember-simple-auth/authenticators/base';
import { run } from '@ember/runloop';
import ENV from 'weldnote/config/environment';
import { service } from '@ember/service';

@classic
export default class CustomAuthenticator extends Base {
  @service
  session;

  @service
  ajax;

  @service
  sharedStorage;

  @service
  userSession;

  timeoutId = -1;

  async authenticate(options) {
    return new Promise((resolve, reject) => {
      if (options && options.username && options.password) {
        this.authenticateWithCredentials(resolve, reject, options.username, options.password);
      } else {
        this.trySsoAuthentication(resolve, reject);
      }
    });
  }

  restore(previousToken) {
    return new Promise((resolve, reject) => {
      this.validateCurrentTokens(previousToken)
        .then((token) => {
          this.scheduleExpiredTokenCheck();
          run(null, resolve, token);
        })
        .catch(() => {
          run(null, reject, 'Invalid token, needs to login again');
        });
    });
  }

  invalidate() {
    return new Promise((resolve, reject) => {
      this.userSession.isLoggedInAsCustomerSupport = false;
      this.sharedStorage.clear().then(() => {
        run(null, resolve, null);
      });
    });
  }

  authenticateWithCredentials(resolve, reject, username, password) {
    let formData = new FormData();
    formData.append('client_id', ENV.msalWeldCloud.clientId);
    formData.append('scope', `${ENV.msalWeldCloud.scope} openid profile offline_access`);
    formData.append('grant_type', 'password');
    formData.append('username', username);
    formData.append('password', password);

    this.ajax
      .post(`${ENV.msalWeldCloud.authority}/oauth2/v2.0/token`, {
        processData: false,
        contentType: false,
        data: formData,
      })
      .then((response) => {
        // We consider that the token expires after 12 hours, although the real expiration date
        // is available in the response token. This time interval can be configured in Azure's
        // User Flow. However, this implementation (the one used in WeldCloud) makes it easier
        // to handle token renewals, as it simplifies the process.
        let expiresOn = new Date();
        expiresOn.setHours(expiresOn.getHours() + 12);

        let parsedResponse = {
          accessToken: response.access_token,
          refreshToken: response.refresh_token,
          expiresOn: expiresOn.getTime().toString(),
        };

        // Fetch the /me endpoint to check if we have a support session ongoing
        this.ajax.request(`${ENV.orgApiUrl}/org/odata/users/me`, {
          headers: {
            'Client-Application': 'weldcloud-notes',
            'Authorization': `Bearer ${response.access_token}`,
            'ocp-apim-subscription-key': ENV.azureSubscriptionManagementId
          }
        }).then((response => {
          // If there's a support request token, use that, otherwise we use the original
          // access token
          if (response.supportRequestToken && response.supportRequestToken != '') {
            parsedResponse.accessToken = response.supportRequestToken;
          }
          this.sharedStorage.setValue('msal.token', JSON.stringify(parsedResponse)).then(() => {
            this.scheduleExpiredTokenCheck();
            run(null, resolve, parsedResponse);
          });
        }));

      })
      .catch((reason) => {
        run(null, reject, reason);
      });
  }

  trySsoAuthentication(resolve, reject) {
    this.validateCurrentTokens()
      .then((token) => {
        this.scheduleExpiredTokenCheck();
        run(null, resolve, token);
      })
      .catch(() => {
        run(null, reject, 'Invalid token, needs to login again');
      });
  }

  timeUntilTokenExpiration(tokenResponse) {
    if (tokenResponse && tokenResponse.accessToken && tokenResponse.expiresOn) {
      let now = new Date();
      let expires = new Date();
      expires.setTime(tokenResponse.expiresOn);
      let timeUntilExpiration = expires - now;
      return timeUntilExpiration;
    } else {
      return 0;
    }
  }

  hasTokenExpired(tokenResponse) {
    return this.timeUntilTokenExpiration(tokenResponse) <= 0;
  }

  scheduleExpiredTokenCheck() {
    window.clearTimeout(this.timeoutId);
    this.timeoutId = window.setTimeout(() => {
      this.validateCurrentTokens()
        .then(() => {
          this.scheduleExpiredTokenCheck();
        })
        .catch(() => {
          this.session.invalidate();
        });
    }, 30000);
  }

  validateCurrentTokens(previousToken) {
    return new Promise((resolve, reject) => {
  
      const handleLocalFallback = (e) => {
        console.log(e);
        // Fallback to local storage
        let currentToken = previousToken ?? this.session?.data?.authenticated;
        if (!this.hasTokenExpired(currentToken)) {
          run(null, resolve, currentToken);
        } else {
          run(null, reject, 'Invalid token');
        }
      };

      this.sharedStorage
        .getValue('msal.supportToken')
        .then((supportTokenStr) => {
          let supportToken = JSON.parse(supportTokenStr);
          if (!this.hasTokenExpired(supportToken)) {
            this.userSession.isLoggedInAsCustomerSupport = true;
            run(null, resolve, supportToken);
          } else {
            // If supportToken is expired, try msal.token
            this.sharedStorage
              .getValue('msal.token')
              .then((sharedTokenStr) => {
                let sharedToken = JSON.parse(sharedTokenStr);
                if (!this.hasTokenExpired(sharedToken)) {
                  run(null, resolve, sharedToken);
                } else {
                  run(null, reject, 'Invalid token');
                }
              })
              .catch(handleLocalFallback);
          }
        })
        .catch(handleLocalFallback);
    });
  }
}
