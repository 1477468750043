import Enumerable from '@ember/array/-private/enumerable';
import { computed, get, set } from '@ember/object';
import Service, { service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import * as Sentry from '@sentry/browser';
import classic from 'ember-classic-decorator';
import { task } from 'ember-concurrency';
import DS from 'ember-data';
import IntlService from 'ember-intl/services/intl';
import ENV from 'weldnote/config/environment';
import Account from 'weldnote/models/account';
import Feature from 'weldnote/models/feature';
import Constants from 'weldnote/utils/constants';
import planResolver from 'weldnote/utils/subscription/plan-resolver';
import WeldcloudNotesData from './weldcloud-notes-data';
import WeldcloudData from './weldcloud-data';

const {
  PERMISSIONS,
  UNIT_SYSTEM,
  UNITS,
  DEFAULT_DATE_FORMAT,
  WELDNOTE_PLANS,
  DEFAULT_SMALL_YEAR_FORMAT,
} = Constants;

@classic
export default class UserSessionService extends Service {
  @service
  declare store: DS.Store;

  @service
  declare intl: IntlService;

  @service
  declare session: any;

  @service
  declare analytics: any;

  @service
  declare welderCertificateData: any;

  @service()
  declare crisp: any;

  get chat() {
    return this.crisp;
  }

  @service()
  declare weldnoteData: any;

  @service
  declare weldcloudNotesData: WeldcloudNotesData;

  @service
  declare weldcloudData: WeldcloudData;

  get data() {
    return this.weldnoteData;
  }

  get userEmail(): string | undefined {
    return this.account?.email;
  }

  get userClient() {
    return this.account?.client;
  }

  get userLanguage() {
    return this.account?.language;
  }

  get userName() {
    return this.account?.name;
  }

  @computed('userEmail', 'userName')
  get userLabel() {
    if (!isEmpty(get(this, 'userName'))) {
      return get(this, 'userName');
    }
    return get(this, 'userEmail');
  }

  @tracked
  account: Account | null = null;

  // List of application names in the weld cloud suite for which
  // the user has a valid license
  @tracked
  applications: any = null;

  @tracked
  separatorOfDecimals = '.';

  @tracked
  isLoggedInAsCustomerSupport: boolean = false;

  get isAdmin(): boolean {
    return this.account?.admin || false;
  }

  get clientLabel() {
    return this.account?.client;
  }

  get decimalSeparator() {
    return this.separatorOfDecimals;
  }

  get subscribedCompanies() {
    return this.account?.companiesSubscribed || [];
  }

  get subscribedCompanyIds() {
    let { subscribedCompanies } = this;
    if (!isEmpty(subscribedCompanies)) {
      return this.subscribedCompanies
        .map((item) => {
          return `${item.get('id')}`;
        })
        .join(',');
    }
    return '';
  }

  get isAccountLoaded() {
    return this.account && !isEmpty(this.account);
  }

  get accountAcronym() {
    let { userLabel: label } = this;
    let userLabel = '';
    if (label) {
      userLabel = label;
    }
    if (!isEmpty(userLabel)) {
      if (userLabel.indexOf(' ') > 0) {
        let userParts = userLabel.split(' ');
        return `${userParts[0].charAt(0)}${userParts[1].charAt(0)}`;
      } else {
        if (userLabel.length > 1) {
          return userLabel.substr(0, 2);
        }
      }
    }
    return 'U';
  }

  globalFeatures: Feature[] = [];

  @computed('account.features.[]', 'globalFeatures')
  get features() {
    if (!this.isAccountLoaded) {
      return [];
    }
    let userFeatures: Enumerable<Feature> | undefined = this.account?.features;
    if (!userFeatures || isEmpty(userFeatures)) {
      userFeatures = [];
    }

    let result = this.globalFeatures.concat(userFeatures.toArray());

    let finalResult = result.map((feature) => {
      return feature.get('name');
    });
    return finalResult;
  }

  loadFeatures = task(
    {
      drop: true,
    },
    async () => {
      let { store } = this;
      let result = await store.findAll('feature');
      return result.toArray();
    }
  );

  loadApplications = task(
    {
      drop: true,
    },
    async () => {
      let applications = await this.weldcloudData.listOfApplications();
      if (applications) {
        set(this, 'applications', applications);
      } else {
        set(this, 'applications', []);
      }
    }
  );

  loadUser = task(
    {
      drop: true,
    },
    async () => {
      let { analytics, intl, chat, session, data } = this;

      let features: Feature[] = await this.loadFeatures.perform();
      set(this, 'globalFeatures', features.filterBy('activeForAll', true));

      let account = await data.loadCurrentUser();
      let userId = account.get('id');

      this.account = account;
      if (!isEmpty(account.decimalSeparator)) {
        set(this, 'separatorOfDecimals', account.decimalSeparator);
      }
      let { language, client } = account;
      if (isEmpty(language)) {
        language = ENV.intl.defaultLocale;
      } else {
        if (language === 'en') {
          language = 'en-us';
        } else if (language === 'pt') {
          language = 'pt-pt';
        } else if (language === 'br') {
          language = 'pt-pr';
        } else if (language === 'es') {
          language = 'es-es';
        } else if (language === 'de') {
          language = 'de-de';
        }
      }
      if (isEmpty(client)) {
        client = 'Weldnote';
      }
      intl.setLocale(language);
      analytics.identify(userId, {
        email: account.get('email'),
        name: account.get('name'),
        language: account.get('language'),
        plan: account.get('subscriptionType'),
        typeOfPlan: account.get('subscriptionPlan'),
        client: `${account.get('client')} (${account.get('clientId')})`,
      });
      // Store the userId
      session.set('data.userId', userId);

      // Load all essential data
      await this.weldcloudNotesData.fetchAllData.perform();

      this.weldcloudNotesData.fetchSecondaryData.perform();

      if (ENV.environment === 'production') {
        let userId = this.accountId;
        chat.identifyUser(this.userName, this.userEmail);
        Sentry.configureScope((scope:any) => {
          scope.setUser({
            id: this.accountId,
            email: this.userEmail,
          });
        });

        if (window.hj) {
          window.hj('identify', userId, {
            email: this.userEmail,
            name: account.get('name'),
            language: account.get('language'),
            typeOfPlan: account.get('subscriptionPlan'),
          });
        }
      }
      // Add your own custom attributes here. Some EXAMPLES:
      // 'Signed up': '2019—06-20Z', // Signup date in ISO-8601 format.
      // 'Last purchase category': 'Electronics', // Send strings with quotes around them.
      // 'Total purchases': 15, // Send numbers without quotes.
      // 'Last purchase date': '2019-06-20Z', // Send dates in ISO-8601 format.
      // 'Last refund date': null, // Send null when no value exists for a user.});

      this.welderCertificateData.queryCertificateCount.perform();

      this.loadApplications.perform();
    }
  );

  get accountId() {
    return this.account?.id || '';
  }

  get dateFormat() {
    let format = this.account?.dateFormat;
    if (!isEmpty(format)) {
      return format;
    }
    return DEFAULT_DATE_FORMAT;
  }

  get smallDateFormat() {
    let format = this.account?.dateFormat || '';
    if (!isEmpty(format)) {
      return format.replace('YYYY', 'YY');
    }
    return DEFAULT_SMALL_YEAR_FORMAT;
  }

  get isTrial() {
    return this.account?.isTrial;
  }

  get isViewer(): boolean {
    return this.account?.notesRole === 'viewer';
  }

  get isOwner(): boolean {
    return this.account?.notesRole === 'owner';
  }

  _hasPermission(permission: string): boolean {
    if (!permission && isEmpty(permission)) {
      throw 'Cannot check an empty permission';
    }
    if (this.isAccountLocked) {
      return false;
    }
    let { account } = this;
    if (!isEmpty(account)) {
      if (
        (this.isWritePermission(permission) && this.isOwner) ||
        this.account?.hasPermission(PERMISSIONS.ACCOUNT_ADMIN)
      ) {
        if (this.account?.hasRoles) {
          return (
            this.account.hasPermission(permission) ||
            this.account.hasPermission(PERMISSIONS.ACCOUNT_ADMIN)
          );
        } else {
          return true;
        }
      } else if (this.isViewer && this.isReadPermission(permission)) {
        return true;
      } else {
        return (
          account?.hasPermission(permission) ||
          account?.hasPermission(PERMISSIONS.ACCOUNT_ADMIN) ||
          this.isAdmin
        );
      }
    }
    return false;
  }

  isReadPermission(permission: string): boolean {
    return (
      permission === PERMISSIONS.PQR_READ ||
      permission === PERMISSIONS.WPS_READ ||
      permission === PERMISSIONS.PWPS_READ ||
      permission === PERMISSIONS.WELDER_CERTIFICATE_READ ||
      permission === PERMISSIONS.WELDING_BOOK_READ ||
      permission === PERMISSIONS.PROJECT_READ
    );
  }

  isWritePermission(permission: string): boolean {
    return (
      permission === PERMISSIONS.ACCOUNT_ADMIN ||
      permission === PERMISSIONS.PQR_CREATE ||
      permission === PERMISSIONS.PQR_DELETE ||
      permission === PERMISSIONS.PQR_CREATE_WPS ||
      permission === PERMISSIONS.PQR_EDIT_APPROVAL_RANGE ||
      permission === PERMISSIONS.PQR_ARCHIVE ||
      permission === PERMISSIONS.WPS_CREATE ||
      permission === PERMISSIONS.WPS_CREATE_REVISION ||
      permission === PERMISSIONS.WPS_ASSIGN_STAFF ||
      permission === PERMISSIONS.WPS_DELETE ||
      permission === PERMISSIONS.WPS_ARCHIVE ||
      permission === PERMISSIONS.WPS_VERIFY ||
      permission === PERMISSIONS.WPS_APPROVE ||
      permission === PERMISSIONS.WPS_REVOKE ||
      permission === PERMISSIONS.PWPS_CREATE ||
      permission === PERMISSIONS.PWPS_DELETE ||
      permission === PERMISSIONS.PWPS_CREATE_CERTIFICATE ||
      permission === PERMISSIONS.PWPS_CREATE_PQR ||
      permission === PERMISSIONS.WELDER_CERTIFICATE_CREATE ||
      permission === PERMISSIONS.WELDER_CERTIFICATE_DELETE ||
      permission === PERMISSIONS.WELDER_CERTIFICATE_ARCHIVE ||
      permission === PERMISSIONS.WELDER_CERTIFICATE_REVALIDATE ||
      permission === PERMISSIONS.WELDER_CERTIFICATE_EDIT_APPROVAL_RANGE ||
      permission === PERMISSIONS.WELDING_BOOK_CREATE ||
      permission === PERMISSIONS.WELDING_BOOK_CREATE_REVISION ||
      permission === PERMISSIONS.WELDING_BOOK_VERIFY ||
      permission === PERMISSIONS.WELDING_BOOK_APPROVE ||
      permission === PERMISSIONS.WELDING_BOOK_ARCHIVE ||
      permission === PERMISSIONS.WELDING_BOOK_DELETE ||
      permission === PERMISSIONS.FILLER_MATERIAL_CREATE ||
      permission === PERMISSIONS.FILLER_MATERIAL_DELETE ||
      permission === PERMISSIONS.BASE_MATERIAL_CREATE ||
      permission === PERMISSIONS.BASE_MATERIAL_DELETE ||
      permission === PERMISSIONS.WELDER_ARCHIVE ||
      permission === PERMISSIONS.WELDER_CREATE ||
      permission === PERMISSIONS.WELDER_DELETE ||
      permission === PERMISSIONS.PROJECT_ARCHIVE ||
      permission === PERMISSIONS.PROJECT_CREATE ||
      permission === PERMISSIONS.PROJECT_DELETE ||
      permission === PERMISSIONS.PROJECT_WELD_CREATE ||
      permission === PERMISSIONS.PROJECT_WELD_DELETE ||
      permission === PERMISSIONS.GENERIC_WRITE_PERMISSION ||
      permission === PERMISSIONS.QMATRIX_CREATE ||
      permission === PERMISSIONS.QMATRIX_DELETE
    );
  }

  // Permission computed properties
  get canCreatePQR(): boolean {
    return this._hasPermission(PERMISSIONS.PQR_CREATE);
  }

  get canDeletePQR(): boolean {
    return this._hasPermission(PERMISSIONS.PQR_DELETE);
  }

  get canCreateWPSFromPQR(): boolean {
    return this._hasPermission(PERMISSIONS.PQR_CREATE_WPS);
  }

  get canEditPQRApprovalRange(): boolean {
    return this._hasPermission(PERMISSIONS.PQR_EDIT_APPROVAL_RANGE);
  }

  get canCreateWPS(): boolean {
    return this._hasPermission(PERMISSIONS.WPS_CREATE);
  }

  get canCreateRevisionWPS(): boolean {
    return this._hasPermission(PERMISSIONS.WPS_CREATE_REVISION);
  }

  get canDeleteWPS(): boolean {
    return this._hasPermission(PERMISSIONS.WPS_DELETE);
  }

  get canAssignStaffWPS(): boolean {
    return this._hasPermission(PERMISSIONS.WPS_ASSIGN_STAFF);
  }

  get canCreateCertificate(): boolean {
    return this._hasPermission(PERMISSIONS.WELDER_CERTIFICATE_CREATE);
  }

  get canDeleteCertificate(): boolean {
    return this._hasPermission(PERMISSIONS.WELDER_CERTIFICATE_DELETE);
  }

  get canArchiveCertificate(): boolean {
    return this._hasPermission(PERMISSIONS.WELDER_CERTIFICATE_ARCHIVE);
  }

  get canRevalidateCertificate(): boolean {
    return this._hasPermission(PERMISSIONS.WELDER_CERTIFICATE_REVALIDATE);
  }

  get canEditCertificateApprovalRange(): boolean {
    return this._hasPermission(PERMISSIONS.WELDER_CERTIFICATE_EDIT_APPROVAL_RANGE);
  }

  get canCreateWeldingBook(): boolean {
    return this._hasPermission(PERMISSIONS.WELDING_BOOK_CREATE);
  }

  get canArchiveWeldingBook(): boolean {
    return this._hasPermission(PERMISSIONS.WELDING_BOOK_ARCHIVE);
  }

  get canCreateWeldingBookRevision(): boolean {
    return this._hasPermission(PERMISSIONS.WELDING_BOOK_CREATE_REVISION);
  }

  get canVerifyWeldingBook(): boolean {
    return this._hasPermission(PERMISSIONS.WELDING_BOOK_VERIFY);
  }

  get canApproveWeldingBook(): boolean {
    return this._hasPermission(PERMISSIONS.WELDING_BOOK_APPROVE);
  }

  get canDeleteWeldingBook(): boolean {
    return this._hasPermission(PERMISSIONS.WELDING_BOOK_DELETE);
  }

  get canCreateFillerMaterial(): boolean {
    return this._hasPermission(PERMISSIONS.FILLER_MATERIAL_CREATE);
  }

  get canDeleteFillerMaterial(): boolean {
    return this._hasPermission(PERMISSIONS.FILLER_MATERIAL_DELETE);
  }

  get canCreateBaseMaterial(): boolean {
    return this._hasPermission(PERMISSIONS.BASE_MATERIAL_CREATE);
  }

  get canDeleteBaseMaterial(): boolean {
    return this._hasPermission(PERMISSIONS.BASE_MATERIAL_DELETE);
  }

  get canCreateWelder(): boolean {
    return this._hasPermission(PERMISSIONS.WELDER_CREATE);
  }

  get canDeleteWelder(): boolean {
    return this._hasPermission(PERMISSIONS.WELDER_DELETE);
  }

  get isAccountAdmin(): boolean {
    return this._hasPermission(PERMISSIONS.ACCOUNT_ADMIN);
  }

  get canCreateBaseMaterialCertificate(): boolean {
    return this.canCreateProject;
  }

  get canCreateFillerMaterialCertificate(): boolean {
    return this.canCreateProject;
  }

  get canCreateNDTReportCertificate(): boolean {
    return this.canCreateProject;
  }

  get canCreatePwhtReportCertificate(): boolean {
    return this.canCreateProject;
  }

  get canCreateGeneralDrawing(): boolean {
    return this.canCreateProject;
  }

  get canCreateComponentDrawing(): boolean {
    return this.canCreateProject;
  }

  get canCreateProjectDocument(): boolean {
    return this.canCreateProject;
  }

  get canCreateProject(): boolean {
    return this._hasPermission(PERMISSIONS.PROJECT_CREATE);
  }

  get canDeleteProject(): boolean {
    return this._hasPermission(PERMISSIONS.PROJECT_DELETE);
  }

  get canArchivePQR(): boolean {
    return this._hasPermission(PERMISSIONS.PQR_ARCHIVE);
  }

  get canArchiveWPS(): boolean {
    return this._hasPermission(PERMISSIONS.WPS_ARCHIVE);
  }

  get canArchiveWelder(): boolean {
    return this._hasPermission(PERMISSIONS.WELDER_ARCHIVE);
  }

  get canArchiveProject(): boolean {
    return this._hasPermission(PERMISSIONS.PROJECT_ARCHIVE);
  }

  get canVerifyWps(): boolean {
    return this._hasPermission(PERMISSIONS.WPS_VERIFY);
  }

  get canApproveWps(): boolean {
    return this._hasPermission(PERMISSIONS.WPS_APPROVE);
  }

  get canRevokeWps(): boolean {
    return this._hasPermission(PERMISSIONS.WPS_REVOKE);
  }

  get canSave(): boolean {
    return this._hasPermission(PERMISSIONS.GENERIC_WRITE_PERMISSION);
  }

  get canCreateQMatrix(): boolean {
    return this._hasPermission(PERMISSIONS.QMATRIX_CREATE);
  }

  // End Permissions

  @computed('applications.@each')
  get hasProductivity() {
    let { applications } = this;
    if (applications) {
      for (let i = 0; i < applications.length; i++) {
        if (applications[i] === 'weldcloud') {
          return true;
        }
      }
    }
    return false;
  }

  get isMetricSystem() {
    return this.account?.unitSystem === UNIT_SYSTEM.METRIC;
  }

  get isImperialSystem() {
    return this.account?.unitSystem === UNIT_SYSTEM.IMPERIAL;
  }

  get unitSystem(): string | undefined {
    return this.account?.unitSystem;
  }

  get isAccountLocked() {
    return this.account?.accountLocked;
  }

  get subscriptionInfo() {
    return this.account?.subscriptionInfo;
  }

  get temperatureUnit() {
    if (this.isMetricSystem) {
      return UNITS.CELSIUS;
    } else if (this.isImperialSystem) {
      return UNITS.FAHRENHEIT;
    }
    return '';
  }

  get distanceUnit() {
    if (this.isMetricSystem) {
      return this.intl.t(`lov.units.${UNITS.MILLIMETER}`);
    } else if (this.isImperialSystem) {
      return this.intl.t(`lov.units.${UNITS.INCH}`);
    }
    return '';
  }

  get flowUnit(): string {
    if (this.isMetricSystem) {
      return UNITS.LITER_MINUTE;
    } else if (this.isImperialSystem) {
      return UNITS.CUBIC_FEET_HOUR;
    }
    return '';
  }

  get heatDistanceUnit() {
    if (this.isMetricSystem) {
      return this.intl.t(`lov.units.${UNITS.KILO_JOULE_MILLIMETER}`);
    } else if (this.isImperialSystem) {
      return this.intl.t(`lov.units.${UNITS.KILO_JOULE_INCH}`);
    }
    return '';
  }

  get wireSpeedUnit() {
    if (this.isMetricSystem) {
      return this.intl.t(`lov.units.${UNITS.METER_MINUTE}`);
    } else if (this.isImperialSystem) {
      return this.intl.t(`lov.units.${UNITS.INCH_MINUTE}`);
    }
    return '';
  }

  get travelSpeedUnit() {
    if (this.isMetricSystem) {
      return this.intl.t(`lov.units.${UNITS.MILLIMETER_MINUTE}`);
    } else if (this.isImperialSystem) {
      return this.intl.t(`lov.units.${UNITS.INCH_MINUTE}`);
    }
    return '';
  }

  get forceUnit() {
    if (this.isMetricSystem) {
      return UNITS.NEWTON;
    } else if (this.isImperialSystem) {
      return UNITS.POUNDS;
    }
    return '';
  }

  get areaUnit() {
    if (this.isMetricSystem) {
      return UNITS.SQUARE_MILLIMETER;
    } else if (this.isImperialSystem) {
      return UNITS.SQUARE_INCH;
    }
    return '';
  }

  get pressureUnit() {
    if (this.isMetricSystem) {
      return UNITS.NEWTON_SQUARE_MILIMETER;
    } else if (this.isImperialSystem) {
      return UNITS.PSI;
    }
    return '';
  }

  get energyUnit() {
    if (this.isMetricSystem) {
      return UNITS.JOULE;
    } else if (this.isImperialSystem) {
      return UNITS.FEET_PER_POUND;
    }
    return '';
  }

  async reloadUser() {
    let account: Account = await this.data.loadCurrentUser();
    set(this, 'account', account);
    this.intl.setLocale(account.language || 'en-us');
  }

  hasFeature(name: string) {
    if (ENV.environment === 'development') {
      if (name === 'ge-requirements' || name === 'ge-wps-pass') {
        return false;
      } else {
        return true;
      }
    }
    let collection = get(this, 'features');

    if (isEmpty(collection) || isEmpty(name)) {
      return false;
    }

    return collection.includes(name);
  }

  isAllowedByPlan(activity = '') {
    let plan = this.account?.subscriptionPlan;
    let planVerifier = planResolver(plan);
    return planVerifier(activity);
  }

  get isPlanWpsOnly() {
    return this.account?.subscriptionPlan === WELDNOTE_PLANS.WPS_ONLY;
  }

  get isPlanCertificates() {
    return this.account?.subscriptionPlan === WELDNOTE_PLANS.CERTIFICATES;
  }

  @computed('account.{subscriptionPlan,isTrial}')
  get isPlanQuality() {
    return this.account?.isTrial || this.account?.subscriptionPlan === WELDNOTE_PLANS.QUALITY;
  }

  get permissionsLink(): string {
    return `${ENV.esabCloudLink}/settings/groups`;
  }

  get personnelLink(): string {
    return `${ENV.esabCloudLink}/settings/personnel`;
  }

  get sitesLink(): string {
    return `${ENV.esabCloudLink}/settings/sites`;
  }

  get profileLink(): string {
    return `${ENV.esabCloudLink}/settings/my-settings`;
  }

  get customerSupportLink(): string {
    return `${ENV.esabCloudLink}/settings/customer-support`;
  }
}
