import { computed } from '@ember/object';
import { belongsTo, attr } from '@ember-data/model';
import BaseModel from 'weldnote/models/base-model';
import { memberAction } from 'ember-api-actions';
import FileUpload from './file-upload';
import GeneralDrawing from './general-drawing';

export default class ComponentDrawing extends BaseModel {
  @attr('string')
  declare title?: string;

  @attr('string')
  declare referenceNumber?: string;

  @belongsTo('file-upload', { async: false })
  declare drawingContent: FileUpload;

  @attr('string')
  declare drawingContentPath?: string;

  @attr('string', { defaultValue: '0' })
  declare revision: string;

  @attr('string')
  declare revisionStatus: string;

  @belongsTo('general-drawing', { async: false })
  declare referencedDrawing: GeneralDrawing;

  @attr('number')
  declare createdOn: number;

  get active(): boolean {
    return this.revisionStatus === 'ACT';
  }

  get visualLabel(): string {
    return this.referenceNumber || '';
  }

  @computed('referenceNumber', 'revision')
  get visualLabelWithRev() {
    return `${this.referenceNumber} (Rev. ${this.revision})`;
  }

  validations = {
    title: { presence: true },
    referenceNumber: { presence: true },
    revision: { presence: true },
  };

  metadata = {
    modelName: 'component-drawing',
    title: { required: true },
    referenceNumber: { required: true },
    revision: { required: true },
  };

  updateDrawingReference = memberAction({ path: 'updateDrawingReference', type: 'PUT' });

  createRevision = memberAction({ path: 'createRevision', type: 'PUT' });
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'component-drawing': ComponentDrawing;
  }
}
