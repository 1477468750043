import { belongsTo, attr, AsyncBelongsTo } from '@ember-data/model';
import BaseModel from 'weldnote/models/base-model';
import { memberAction } from 'ember-api-actions';
import FileUpload from './file-upload';

export default class ProjectDocument extends BaseModel {
  @attr('string')
  declare documentTitle?: string;

  @attr('string')
  declare documentNumber?: string;

  @attr('string', { defaultValue: '0' })
  declare revision: string;

  @attr('string')
  declare type?: string;

  @attr('string')
  declare comments?: string;

  @attr('boolean', { defaultValue: true })
  declare includeInDataBook: boolean;

  @belongsTo('file-upload')
  declare document: AsyncBelongsTo<FileUpload>;

  @attr('string')
  declare revisionStatus?: string;

  @attr('number')
  declare createdOn: number;

  get active(): boolean {
    return this.revisionStatus === 'ACT';
  }

  get visualLabel(): string {
    return this.documentTitle || '';
  }

  validations = {
    documentTitle: { presence: true },
    documentNumber: { presence: true },
    revision: { presence: true },
  };

  metadata = {
    modelName: 'project-document',
    listRoute: 'project-documents',
    editRoute: 'project-document',
    documentTitle: { required: true },
    documentNumber: { required: true },
    revision: { required: true },
  };

  updateDocumentRevisions = memberAction({ path: 'updateDocumentRevisions', type: 'PUT' });
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'project-document': ProjectDocument;
  }
}
