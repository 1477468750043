import { computed } from '@ember/object';
import { belongsTo, attr, AsyncBelongsTo } from '@ember-data/model';
import BaseModel from 'weldnote/models/base-model';
import { memberAction } from 'ember-api-actions';
import FileUpload from './file-upload';

export default class GeneralDrawing extends BaseModel {
  @attr('string')
  declare title?: string;

  @attr('string')
  declare referenceNumber?: string;

  @belongsTo('file-upload')
  declare drawingContent: AsyncBelongsTo<FileUpload>;

  @attr('string')
  declare drawingContentPath?: string;

  @attr('string', { defaultValue: '0' })
  declare revision: string;

  @attr('string')
  declare revisionStatus?: string;

  @attr('number')
  declare createdOn: number;

  get active(): boolean {
    return this.revisionStatus === 'ACT';
  }

  get visualLabel(): string {
    return this.referenceNumber || '';
  }

  @computed('referenceNumber', 'revision')
  get visualLabelWithRev(): string {
    return `${this.referenceNumber} (Rev. ${this.revision})`;
  }

  validations = {
    title: { presence: true },
    referenceNumber: { presence: true },
    revision: { presence: true },
  };

  metadata = {
    modelName: 'general-drawing',
    title: { required: true },
    referenceNumber: { required: true },
    revision: { required: true },
  };

  updateDrawingReference = memberAction({ path: 'updateDrawingReference', type: 'PUT' });

  createRevision = memberAction({ path: 'createRevision', type: 'PUT' });
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'general-drawing': GeneralDrawing;
  }
}
