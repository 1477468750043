import { belongsTo, attr, AsyncBelongsTo } from '@ember-data/model';
import { isEmpty } from '@ember/utils';
import { computed } from '@ember/object';
import BaseModel from 'weldnote/models/base-model';
import { memberAction } from 'ember-api-actions';
import Constants from 'weldnote/utils/constants';
import FileUpload from './file-upload';
import MiscellaneousCertificateType from './miscellaneous-certificate-type';
import Company from './company';
import Welder from './welder';

const { MISCELLANEOUS_CERTIFICATE_STATE } = Constants;

export default class MiscellaneousCertificate extends BaseModel {
  @attr('string')
  declare certificateNumber?: string;

  @attr('date')
  declare expirationDate?: Date;

  @attr('decimal-value')
  declare requalificationPeriod?: number;

  @belongsTo('file-upload')
  declare certificateDocument: AsyncBelongsTo<FileUpload>;

  @belongsTo('miscellaneous-certificate-type')
  declare type: AsyncBelongsTo<MiscellaneousCertificateType>;

  @attr('string')
  declare certificateState?: string;

  @belongsTo('company')
  declare company: AsyncBelongsTo<Company>;

  @belongsTo('welder')
  declare certificatePerson: Welder;

  get visualLabel() {
    return this.certificateNumber;
  }

  validations = {
    certificateNumber: { presence: true },
    type: { presence: true },
    certificatePerson: { presence: true },
    requalificationPeriod: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 99.99,
      },
    },
  };

  metadata = {
    modelName: 'miscellaneous-certificate',
    certificateNumber: { required: true },
    type: { required: true },
    certificatePerson: { required: true },
  };

  @computed('requalificationPeriod', 'expirationDate')
  get canRevalidate() {
    let { requalificationPeriod, expirationDate } = this;
    if (
      requalificationPeriod &&
      !isEmpty(requalificationPeriod) &&
      !isNaN(requalificationPeriod) &&
      !isEmpty(expirationDate)
    ) {
      return true;
    }
    return false;
  }

  get isArchived(): boolean {
    return this.certificateState === MISCELLANEOUS_CERTIFICATE_STATE.ARCHIVED;
  }

  get isExpired() {
    return this.certificateState === MISCELLANEOUS_CERTIFICATE_STATE.EXPIRED;
  }

  revalidateCertificate() {
    if (this.canRevalidate) {
      return this.revalidate({});
    }
    return false;
  }

  revalidate = memberAction({ path: 'revalidate' });

  archive = memberAction({ path: 'archiveCertificate' });

  unarchive = memberAction({ path: 'unarchiveCertificate' });

  duplicate = memberAction({ path: 'duplicate' });
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'miscellaneous-certificate': MiscellaneousCertificate;
  }
}
