import Service from '@ember/service';
import ENV from 'weldnote/config/environment';

export default class WeldcloudService extends Service {
  generateWpsLink(wpsId: string): string {
    return `${ENV.esabCloudLink}/productivity/weld-sessions/by-wps?selectedWPS=["${wpsId}"]`;
  }

  generateWelderLink(welderId: string): string {
    return `${ENV.esabCloudLink}/productivity/weld-sessions/by-operator?selectedOperator=["${welderId}"]`;
  }

  generateFleetMachineLink(id: string): string {
    return `${ENV.esabCloudLink}/fleet/machines/machines/${id}`;
  }

  getFleetMachinesLink(): string {
    return `${ENV.esabCloudLink}/fleet/machines/machines/`;
  }
}
